<template>
  <transition name="menu">
    <form-lettertype :is-edit="false"/>
  </transition>
</template>

<script>
import formLettertype from './components/formLettertype'

export default {
  name: 'CreateformLettertype',
  components: { formLettertype }
}
</script>

